.MuiButton-root {
  border-radius: 10px !important;
  font-weight: bold !important;
}

.disabled {
  background-color: rgb(51, 51, 51) !important;
  pointer-events: none;
  cursor: auto !important;
}
