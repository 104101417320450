@import "../../../styles/color.scss";

.card-modal-main {
  padding: 35px;
}
.card-modal-main-fsd
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  color: $fsd-color !important;
}
.card-modal-main-uix
  .MuiTabs-flexContainer
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  color: $uix-color !important;
}
.card-modal-main-fsd .MuiTabs-indicator {
  background-color: $fsd-color !important;
}
.card-modal-main-uix .MuiTabs-indicator {
  background-color: $uix-color !important;
}

.card-modal-dialog {
  .MuiPaper-root {
    min-width: 60vw;
    max-width: 60vw;
    border-radius: 15px;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16/ 9;
    height: unset;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .card-modal-dialog {
    .MuiPaper-root {
      min-width: 60vw;
      max-width: 60vw;
    }
  }
}

@media only screen and (max-width: 900px) {
  .card-modal-dialog {
    .MuiPaper-root {
      min-width: 90vw;
      max-width: 90vw;
    }
  }
  .card-modal-footer {
    flex-wrap: wrap;
  }

  .card-modal-footer-name {
    flex-basis: 100%;
  }
}

.card-modal-footer {
  display: flex;
  place-content: space-between;
  margin-top: 20px;
}

.card-modal-footer-name {
  flex-grow: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  place-content: center;
}
