@import "../../../styles/color.scss";

.navbar {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4vh 13vw 4vh 10vw;
  background-color: $se-light-background-grey;
  position: relative;
  z-index: 1;
  max-width: 1600px;

  .logo {
    pointer-events: all;
    cursor: pointer;
    height: 50px;
    width: auto;
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    padding: 4vh 6vw;
    & .logo {
      max-width: 170px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 4vh 4vw;
    & .logo {
      max-width: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 4vh 6vw;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    padding: 3vh 4vw;
  }
}
