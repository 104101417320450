@import "../../../styles/color.scss";

.hiring-card-container {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  place-content: center;
  height: 100%;
}

.hiring-card-favorite {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  z-index: 1000;
}
.small-divider {
  width: 60px;
  height: 2px;
  background: black;
  margin: 7px 0px;
}
.hiring-card-image-container {
  aspect-ratio: 0.95;
  //border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
  flex: 1;
}
.hiring-card-image-container-uix {
  aspect-ratio: 0.719;
  border-radius: 0px;
}

.hiring-card-footer {
  display: flex;
  margin-top: 20px;
}

.avatar-border {
  border-radius: 50%;
  border: 2px solid $fsw-color;
}

.hiring-card-three-points {
  margin: auto;

  svg {
    color: rgb(204, 204, 204);
  }
}

.hiring-card-main-container {
  position: relative;
  cursor: pointer;
  height: 100%;
  box-shadow: 2px 2px 7px #babbbc;
  border-radius: 10px;
  // button override styles
  outline: none;
  border: none;
  width: 100%;
  padding: 0px;

  @media screen and (min-width: 320px) {
    min-height: 450px;
  }
}

.hiring-card-information-main-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  //display: none;
  position: absolute;
  background: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  pointer-events: none;
  z-index: 4;
  overflow: hidden;

  &.open {
    opacity: 1 !important;
    transition: all 0.3s ease-in-out;
    box-shadow: 4px 4px 11px #babbbc;
    display: block;
    pointer-events: auto;
  }
}

.hiring-card-container {
  display: flex;
  flex-direction: column;
  place-content: space-between;
  padding: 20px;
  z-index: 3;
}
.hiring-card-flip {
  position: absolute;
  bottom: 17px;
  right: 20px;
  z-index: 100;
}
.flip-none {
  opacity: 0;
}
.hiring-card-project-description {
  flex-grow: 1;
}

.hiring-card-information-container {
  display: flex;
  flex-direction: column;
  place-content: space-between;
  padding: 20px;
  height: 100%;

  button {
    font-size: 13px;
    padding: 6px 8px;
  }

  a {
    font-size: 13px;
  }
}

.video-holder,
.video-holder > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.youtube-video {
  position: relative;
  margin-bottom: 5px;
}
.play-button-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  place-content: center;
  place-items: center;
  background: black;
  z-index: 1000;
}
.play-button-container svg {
  transition: 0.3s;
}
.play-button-container svg:hover {
  opacity: 0.7;
}

.blurred {
  filter: blur(3.5px);
  pointer-events: none;
  color: black;
}
