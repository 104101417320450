.footer-container {
  padding: 2% 10%;
  max-width: 1200px;
  color: white;
  margin: auto;
  display: flex;
  place-content: space-between;
}

@media only screen and (max-width: 900px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .partners-picture {
    width: 100% !important;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background: white;
}

.footer-wrapper {
  background: #363738;
}
