@import "../../../styles/color.scss";

.hiring-portal-container {
  min-height: 100vh;
  padding: 5px 13vw 2% 10vw;
  background: #f1f2f3;
  max-width: 1600px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 5px 6vw 2% 6vw;
  }
  @media screen and (max-width: 500px) {
    padding: 5px 4vw 2% 4vw;
  }
}

.hiring-portal-wrapper {
  background: #f1f2f3;
  position: relative;
  z-index: 1;
}

.hiring-portal-upper-section-details {
  background: rgb(230, 230, 230);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  height: 100%;
}

.hiring-portal-upper-section-subtitle {
  margin-top: 15px;
}

.hiring-portal-upper-section-filter {
  background: #e6e6e6;
  border-radius: 10px;
  height: 60px;
  padding: 0px 25px;
  display: flex;
  cursor: pointer;
}
.hiring-portal-filters-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.hiring-portal-filters-container {
  background-image: url("../../../../assets/images/fsw-image.png");
  background-repeat: no-repeat;
  background-color: black;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  padding: 38px 25px 32px;
  width: calc(80vw - 62px);
  z-index: 1;
  border: 8px solid;
  position: relative;
  overflow: hidden;
  transition: border-color 0.4s ease-out;

  @media screen and (max-width: 600px) {
    padding: 20px 15px 17px;
  }

  //transition: all 0.3s ease-in-out;
  //transition-duration: 150ms;
  //transform: scaleY(1);
}

.filters-content {
  position: relative;
  z-index: 2;
  color: white;
}
.se-dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: 0.4s ease-out;
  @media only screen and (max-width: 900px) {
    width: 15px;
    height: 15px;
  }
  @media only screen and (max-width: 600px) {
    width: 10px;
    height: 10px;
  }
}
.se-dot-white {
  background: white;
}
.se-dot-fsw {
  background: $fsw-color;
}
.se-dot-fsd {
  background: $fsd-color;
}
.se-dot-uix {
  background: $uix-color;
}
.se-dot-grey {
  background: #a5a6a9;
}

.fsw-container {
  border-color: $fsw-color;
}
.fsd-container {
  border-color: $fsd-color;
}
.uix-container {
  border-color: $uix-color;
}
.filter-by-container {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    .MuiFormLabel-root {
      font-size: 13px !important;
    }
  }
}

.filters-wrapper {
  background-color: #363738;
  border-radius: 10px;
  padding: 12px 35px;
  margin-top: 30px;
  box-shadow: 1px 1px 4px black;

  @media screen and (max-width: 600px) {
    padding: 7px 20px;
  }
}
.MuiAutocomplete-popper {
  z-index: 1 !important;
}

.hiring-portal-upper-section-filters {
  padding: 10px 30px;
  border-radius: 10px 0 10px 10px;
  background: rgb(230, 230, 230);
  display: flex;
}

@media only screen and (max-width: 600px) {
  .hiring-portal-cards-container {
    .MuiGrid-root {
      width: 100%;
    }
  }
}

.MuiInputLabel-root {
  &.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.MuiChip-root {
  height: 25px !important;
}

.react-tiny-popover-container {
  //transition:  all 0.3s ease-in-out;
  //transition-duration: 150ms;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .hiring-portal-container
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled {
    font-size: 14px !important;
    margin-top: 1px !important;
  }
}

.column {
  flex-direction: column;
}

.flex {
  display: flex;
}

.blurred {
  filter: blur(3px);
  pointer-events: none;
}
